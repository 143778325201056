import AuthorizeRoute from 'app/components/AuthorizeRoute';
import { NotFoundPage } from 'app/components/NotFoundPage/Loadable';
import { SocketProvider } from 'app/contexts/SocketContext';
import { useGlobalSliceSlice } from 'app/slice';
import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import AuthorizationPage from '../Authentication/AuthorizationPage';
import { ChatPage } from '../ChatPage/Loadable';
import ChannelsPage from '../ChannelPage/Channels';
import ChannelsAddRedirectPage from '../ChannelPage/ChannelsAddRedirect';
import Dashboard from './Dasboard';
import SettingPage from '../Setting';
import UserSetting from '../Setting/UserSetting';
import LayoutSetting from 'app/pages/Setting/components/Layout';
import LayoutReport from './LayoutReport';
import { ReportOverview } from 'app/pages/Report/Loadable';
import { ReportProvider } from 'app/contexts/ReportContext';
import UserChangePassword from '../Setting/UserChangePassword';
import UserUpdate from '../Setting/UserUpdate';
import ManageSMEs from '../Setting/SMEManage';
import SubUserList from '../Setting/SubUserList';
import SubUserCreate from '../Setting/SubUserCreate';
import SubUserChangePassword from '../Setting/SubUserChangePassword';
import SubUserUpdateInfo from '../Setting/SubUserUpdateInfo';
import { FullfillmentProvider } from 'app/contexts/FullfillmentContext';
import Operation from '../Fullfillment/Operation';
import { FullfillmentReport } from '../Fullfillment/Loadable';
import { SettlementProvider } from 'app/contexts/SettlementContext';
import { HistoryExportFileSettlementPending, HistoryExportFileSettlementProcessed, SettlementManual } from '../Settlement/Loadable';
import CommissionConfig from '../Setting/CommissionConfig';
import CommissionCreate from '../Setting/CommissionCreate';

const Layout = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { actions } = useGlobalSliceSlice();

  return (
    <Dashboard>
      <Routes>
        <Route
          path={'/chats'}
          element={
            <SocketProvider>
              <ChatPage />
            </SocketProvider>
          }
        />
        <Route
          path={'/report/overview'}
          element={
            <ReportProvider>
              <ReportOverview />
            </ReportProvider>
          }
        />
        <Route
          path={'/fullfillment-manage/operation'}
          element={
            <FullfillmentProvider>
              <Operation />
            </FullfillmentProvider>
          }
        />
        <Route
          path={'/settlement-manage/manual'}
          element={
            <SettlementProvider>
              <SettlementManual />
            </SettlementProvider>
          }
        />
        <Route
          path={'/settlement-manage/exportfile-settlement-pending'}
          element={
            <SettlementProvider>
              <HistoryExportFileSettlementPending />
            </SettlementProvider>
          }
        />
        <Route
          path={'/settlement-manage/exportfile-settlement-processed'}
          element={
            <SettlementProvider>
              <HistoryExportFileSettlementProcessed />
            </SettlementProvider>
          }
        />
        <Route
          path={'/report/fullfillment-report'}
          element={
            <FullfillmentProvider>
              <FullfillmentReport />
            </FullfillmentProvider>
          }
        />
        <Route
          path={'/settings'}
          element={<AuthorizeRoute>
            <SettingPage />
          </AuthorizeRoute>}
        />
        <Route
          path={'/settings/user'}
          element={<LayoutSetting>
            <AuthorizeRoute>
              <UserSetting />
            </AuthorizeRoute>
          </LayoutSetting>
          }
        />
        <Route
          path={'/settings/smes'}
          element={<LayoutSetting>
            <ManageSMEs />
          </LayoutSetting>
          }
        />
        <Route
          path={'/settings/cms'}
          element={<LayoutSetting>
            <CommissionConfig />
          </LayoutSetting>
          }
        />
        <Route
          path={'/settings/cms-create'}
          element={<LayoutSetting>
            <CommissionCreate />
          </LayoutSetting>
          }
        />
        <Route
          path={'/settings/user/change-password'}
          element={<LayoutSetting>
            <AuthorizeRoute>
              <UserChangePassword />
            </AuthorizeRoute>
          </LayoutSetting>
          } />
        <Route
          path={'/settings/user/update'}
          element={<LayoutSetting>
            <AuthorizeRoute>
              <UserUpdate />
            </AuthorizeRoute>
          </LayoutSetting>
          } />
        <Route
          path={'/settings/sub-user'}
          element={<LayoutSetting>
            <AuthorizeRoute>
              <SubUserList />
            </AuthorizeRoute>
          </LayoutSetting>
          } />
        <Route
          path={'/settings/sub-user/create'}
          element={<LayoutSetting>
            <AuthorizeRoute>
              <SubUserCreate />
            </AuthorizeRoute>
          </LayoutSetting>
          } />

        <Route
          path={'/settings/sub-user/change-password/:id'}
          element={<LayoutSetting>
            <AuthorizeRoute>
              <SubUserChangePassword />
            </AuthorizeRoute>
          </LayoutSetting>
          } />

        <Route
          path={'/settings/sub-user/change-info/:id'}
          element={<LayoutSetting>
            <AuthorizeRoute>
              <SubUserUpdateInfo />
            </AuthorizeRoute>
          </LayoutSetting>
          } />
        <Route path={'/'} element={<Navigate to="/settings/smes" />} />
        <Route path={'/error/403'} element={<AuthorizationPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Dashboard>
  );
};

export default memo(Layout);
