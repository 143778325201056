import { WarningFilled } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, Checkbox, Col, Flex, Modal, Radio, Row, Select, Spin, Typography } from "antd";
import dayjs from "dayjs";
import mutate_vrRetryExecuteCommission from "graphql/mutations/mutate_vrRetryExecuteCommission";
import React, { memo, useCallback, useState } from "react";
import { showAlert } from "utils/helper";
import ModalConfirm from "./ModalConfirm";

const { Text } = Typography
interface RetryCommissionModalProps {
    onHide: () => void,
    sme_id?: number
}

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

const RetryCommissionModal = ({
    onHide,
    sme_id
}: RetryCommissionModalProps) => {

    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [show, setShow] = useState(true);
    const [showConfirm, setShowConfirm] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth - 1);

    const yearOptions = [
        { value: currentYear, label: `Năm nay (${currentYear})` },
        { value: currentYear - 1, label: `Năm trước (${currentYear - 1})` }
    ];

    const getMonthOptions = (year: number) => {
        const months = year === currentYear ? currentMonth - 1 : 12;
        return Array.from({ length: months }, (_, i) => ({
            value: i + 1,
            label: `Tháng ${i + 1}`
        }));
    };

    const monthOptions = getMonthOptions(selectedYear);

    const [vrRetryExecuteCommission, { loading }] = useMutation(mutate_vrRetryExecuteCommission, {
        refetchQueries: ['vrConfigCommission'],
        awaitRefetchQueries: true
    })

    return (
        <>
            {showConfirm && <ModalConfirm onHide={() => {
                setShowConfirm(false)
                setShow(true)
            }}
                onConfirm={async () => {
                    const fromDate = dayjs(`${selectedYear}-${selectedMonth}-01`).startOf('month').unix();
                    const toDate = dayjs(`${selectedYear}-${selectedMonth}-01`).endOf('month').unix();
                    let { data } = await vrRetryExecuteCommission({
                        variables: {
                            sme_id: sme_id,
                            from_time: fromDate,
                            to_time: toDate
                        }
                    })
                    if (data?.vrRetryExecuteCommission?.success) {
                        showAlert.success('Chạy tính toán lại CMS thành công')
                        onHide()
                    } else {
                        showAlert.error(data?.vrRetryExecuteCommission?.message || 'Chạy tính toán lại CMS không thành công')
                        onHide()
                    }
                }}
                showComfirm={showConfirm}
            />
            }
            <Modal
                title={false}
                open={show}
                closable={false}
                style={{ top: '30%' }}
                footer={[
                    <Flex className="w-100" align="center" gap={20} justify="center">
                        <Button
                            type="primary"
                            className="btn-base btn-cancel"
                            onClick={onHide}
                        >
                            Hủy
                        </Button>
                        <Button
                            type="primary"
                            className="btn-base"
                            onClick={() => {
                                setShowConfirm(true)
                                setShow(false)
                            }}
                            disabled={loading}
                        >
                            Chạy
                        </Button>
                    </Flex>
                ]}
            >
                <Spin spinning={loading}>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Text>Chọn năm</Text>
                            <Select
                                className="w-100"
                                placeholder="Chọn năm"
                                value={selectedYear}
                                options={yearOptions}
                                onChange={(value) => {
                                    setSelectedYear(value)
                                    if (value == currentYear) {
                                        setSelectedMonth(currentMonth - 1)
                                    }
                                    if (value == currentYear - 1) {
                                        setSelectedMonth(12)
                                    }
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <Text>Chọn tháng cần chạy lại</Text>
                            <Select
                                className="w-100"
                                placeholder="Chọn tháng"
                                value={selectedMonth}
                                options={monthOptions}
                                onChange={(value) => { setSelectedMonth(value) }}
                            />
                        </Col>
                    </Row>
                </Spin>
            </Modal>
        </>
    )
};

export default memo(RetryCommissionModal);