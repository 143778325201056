import { useMutation, useQuery } from "@apollo/client";
import { Button, Card, Flex, Row, Spin, Typography } from "antd";
import { useLayoutContext } from "app/contexts/LayoutContext";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { UserSettingWrapper } from '../Setting.styles';
import { useLocation, useNavigate } from "react-router-dom";
import { showAlert } from "utils/helper";
import { CloseOutlined } from "@ant-design/icons";
import query_agencyGetSme from "graphql/queries/query_agencyGetSme";
import query_smeStore from "graphql/queries/query_smeStore";
import CardCommission from "./CardCommission";
import mutate_vrUpsertConfigCommission from "graphql/mutations/mutate_vrUpsertConfigCommission";
import { checkDuplicateStores, removeTypename, validateFormula } from "./utils";

const { Text } = Typography;
interface LocationState {
    sme_id?: number;
    configs?: any;
    init?: any
}

const CommissionCreate = () => {
    const { appendBreadcrumb } = useLayoutContext();
    const navigate = useNavigate();
    const location = useLocation()
    const state = location?.state as LocationState || {}
    const [dataStore, setDataStore] = useState<any>([])
    const [configs, setConfigs] = useState<any>([])

    useEffect(() => {
        if (!state?.sme_id && !state?.init) {
            setConfigs([])
        } else {
            console.log(state?.init?.applied_indicator?.[0])
            setConfigs([
                {
                    extended_groups: [],
                    groups: [
                        {
                            formula_result: '',
                            formula_rules: [
                                {
                                    applied_indicator: 'nmv',
                                    comparison_operators: '>',
                                    operands: 0,
                                    stores: []
                                }
                            ],
                            stores: []
                        }
                    ]
                }
            ])
        }
        if (!!state?.configs) {
            setConfigs(state?.configs)
        }
    }, [state])

    useLayoutEffect(() => {
        appendBreadcrumb([
            {
                title: 'Cấu hình',
                pathname: '/settings',
            },
            {
                title: 'Quản lý UpS',
                pathname: '/settings/smes',
            },
            {
                title: 'Cấu hình CMS',
                pathname: '/settings/cms-create',
            },
        ]);
    }, []);

    const { data: dataSme, loading: loadingDataSme } = useQuery(query_agencyGetSme, {
        fetchPolicy: 'cache-and-network',
    })

    const { data, loading: loadingDataStore } = useQuery(query_smeStore, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            const optionStore = data?.scAgencySaleStores?.data?.map(item => {
                const logo = data?.op_connector_channels?.find(channel => channel?.code === item?.connector_channel_code)?.logo_asset_url
                return {
                    ...item,
                    value: item?.id,
                    label: item?.name,
                    logo: logo
                }
            })
            setDataStore(optionStore?.filter(item => item?.sme_id === state?.sme_id) || [])
        }
    })

    const [vrUpsertConfigCommission, { loading: loadingVrUpsertConfigCommission }] = useMutation(mutate_vrUpsertConfigCommission, {
        awaitRefetchQueries: true,
        refetchQueries: []
    })



    const validateAllFormulas = () => {
        for (let group of configs) {
            for (let subgroup of group.groups) {
                if (!validateFormula(subgroup.formula_result)) {
                    return false;
                }
            }
            for (let extendedGroup of group.extended_groups) {
                if (!validateFormula(extendedGroup.formula_result)) {
                    return false;
                }
            }
        }
        return true;
    };

    const validateAllStores = () => {
        for (let group of configs) {
            for (let subgroup of group.groups) {
                if (subgroup?.stores?.length === 0) {
                    return false
                }
                for (let rule of subgroup.formula_rules) {
                    if (rule.stores.length === 0) {
                        return false;
                    }
                }
            }
            for (let extendedGroup of group.extended_groups) {
                if (extendedGroup?.stores?.length === 0) {
                    return false
                }
                for (let rule of extendedGroup.formula_rules) {
                    if (rule.stores.length === 0) {
                        return false;
                    }
                }
            }
        }
        return true;
    };

    const handleUpdate = async () => {
        if (!validateAllFormulas()) {
            showAlert.error('Có ít nhất một công thức không hợp lệ. Vui lòng kiểm tra lại.');
            return;
        }

        if (!validateAllStores()) {
            showAlert.error('Vui lòng chọn gian hàng');
            return;
        }

        if (checkDuplicateStores(configs)) {
            showAlert.error('Có ít nhất một gian hàng bị trùng lặp. Vui lòng kiểm tra lại.');
            return;
        }

        const cleanedConfigs = JSON.parse(JSON.stringify(configs));
        removeTypename(cleanedConfigs);

        let { data } = await vrUpsertConfigCommission({
            variables: {
                sme_id: state?.sme_id,
                settings: cleanedConfigs
            }
        })
        if (data?.vrUpsertConfigCommission?.success) {
            showAlert.success('Cập nhật cấu hình CMS thành công')
            navigate('/settings/cms', {
                state: {
                    sme_id: state?.sme_id
                }
            })
        } else {
            showAlert.error(data?.vrUpsertConfigCommission?.message || 'Cập nhật cấu hình CMS không thành công')

        }
    }

    const currentSME = useMemo(() => {
        if (!dataSme?.agencyGetSme?.length) return {}
        return dataSme?.agencyGetSme?.find((item: any) => item?.sme_id === state?.sme_id)
    }, [dataSme])

    const handleDeleteConfig = (index: number) => {
        const newConfigs = [...configs];
        newConfigs.splice(index, 1);
        setConfigs(newConfigs);
    };

    return <UserSettingWrapper>
        <Helmet
            titleTemplate="Cấu hình CMS"
            defaultTitle="Cấu hình CMS"
        >
            <meta name="description" content="Cấu hình CMS" />
        </Helmet>
        <Spin spinning={loadingDataSme || loadingDataStore || loadingVrUpsertConfigCommission}>

            {
                !!configs?.length && configs.map((config, index) => {
                    return <Card style={{ marginBottom: 20 }}>
                        {configs?.length > 1 && <Flex justify="end">
                            <Text style={{ cursor: 'pointer' }} onClick={() => handleDeleteConfig(index)}>
                                <CloseOutlined />
                            </Text>
                        </Flex>}
                        <CardCommission dataStore={dataStore} config={config} configs={configs} setConfigs={setConfigs} index={index} />
                        <Row style={{ marginTop: 20 }}>
                            {config?.extended_groups?.length < 3 && <Button
                                style={{ marginRight: 20 }}
                                className="btn-base btn primary color-base"
                                onClick={() => {
                                    const newCondition = {
                                        formula_result: '',
                                        formula_rules: [
                                            {
                                                applied_indicator: 'nmv',
                                                comparison_operators: '>',
                                                operands: 0,
                                                stores: []
                                            },
                                        ],
                                        stores: config?.groups[0]?.stores
                                    };

                                    const newConfigs = [...configs];
                                    newConfigs[index].extended_groups = [...newConfigs[index].extended_groups, newCondition];
                                    setConfigs(newConfigs);
                                }}
                                color="#ff5629"
                            >Thêm điều kiện phụ</Button>}
                            {index == 0 && <Button
                                type="primary"
                                className="btn-base btn primary"
                                onClick={() => {
                                    const newConfigs = [...configs];
                                    newConfigs.push({
                                        extended_groups: [],
                                        groups: [
                                            {
                                                formula_result: '',
                                                formula_rules: [
                                                    {
                                                        applied_indicator: 'nmv',
                                                        comparison_operators: '>',
                                                        operands: 0,
                                                        stores: []
                                                    }
                                                ],
                                                stores: []
                                            }
                                        ]
                                    });
                                    setConfigs(newConfigs);
                                }}
                            >Thêm cấu hình cho gian hàng khác</Button>}
                        </Row>
                    </Card>
                })
            }
            <Flex justify="end">
                <Button
                    style={{ marginRight: 20 }}
                    onClick={() => {
                        navigate('/settings/cms', {
                            state: {
                                sme_id: state?.sme_id
                            }
                        })
                    }}
                    className="btn-base btn primary"
                >Đóng</Button>
                <Button
                    type="primary"
                    className="btn-base btn primary"
                    disabled={!configs?.length}
                    onClick={handleUpdate}
                >Cập nhật</Button>
            </Flex>
        </Spin>
    </UserSettingWrapper >
};

export default CommissionCreate;
