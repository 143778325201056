import { useMutation, useQuery } from "@apollo/client";
import { Button, Card, Table, Col, Popover, Empty, Flex, List, Row, Spin, Switch, Tooltip, Typography, theme, Image, Input, Form, Dropdown, Select } from "antd";
import { useLayoutContext } from "app/contexts/LayoutContext";
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { UserSettingWrapper } from '../Setting.styles';
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { HistoryOutlined, FieldTimeOutlined } from "@ant-design/icons";
import query_agencyGetSme from "graphql/queries/query_agencyGetSme";
import CardCommission from "./components/CardCommission";
import query_smeStore from "graphql/queries/query_smeStore";
import query_vrConfigCommission from "graphql/queries/query_vrConfigCommission";
import RetryCommissionModal from "./components/RetryCommissionModal";

const { Text } = Typography;
const { Search } = Input;

interface LocationState {
    sme_id?: number;
}

const CommissionConfig = () => {
    const { appendBreadcrumb } = useLayoutContext();
    const navigate = useNavigate();
    const location = useLocation()
    const state = location?.state as LocationState || {}
    const [dataStore, setDataStore] = useState<any>([])
    const [showComfirm, setShowConfirm] = useState<boolean>(false)
    useLayoutEffect(() => {
        appendBreadcrumb([
            {
                title: 'Cấu hình',
                pathname: '/settings',
            },
            {
                title: 'Quản lý UpS',
                pathname: '/settings/smes',
            },
            {
                title: 'Cấu hình CMS',
                pathname: '/settings/cms',
            },
        ]);
    }, []);

    const [configs, setConfigs] = useState<any>([])

    const { data: dataConfig, loading: loadingDataConfig } = useQuery(query_vrConfigCommission, {
        variables: {
            sme_id: state?.sme_id
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (data?.vrConfigCommission?.success) {
                setConfigs(data?.vrConfigCommission?.settings)
            } else {
                setConfigs([])
            }
        },
        skip: !state?.sme_id
    })

    const { data: dataSme, loading: loadingDataSme } = useQuery(query_agencyGetSme, {
        fetchPolicy: 'cache-and-network',
    })

    const { data, loading: loadingDataStore } = useQuery(query_smeStore, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            const optionStore = data?.scAgencySaleStores?.data?.map(item => {
                const logo = data?.op_connector_channels?.find(channel => channel?.code === item?.connector_channel_code)?.logo_asset_url
                return {
                    ...item,
                    value: item?.id,
                    label: item?.name,
                    logo: logo
                }
            })
            setDataStore(optionStore?.filter(item => item?.sme_id === state?.sme_id) || [])
        }
    })

    const currentSME = useMemo(() => {
        if (!dataSme?.agencyGetSme?.length) return {}
        return dataSme?.agencyGetSme?.find((item: any) => item?.sme_id === state?.sme_id)
    }, [dataSme])

    return <UserSettingWrapper>
        <Helmet
            titleTemplate="Cấu hình CMS"
            defaultTitle="Cấu hình CMS"
        >
            <meta name="description" content="Cấu hình CMS" />
        </Helmet>
        {showComfirm && <RetryCommissionModal sme_id={state?.sme_id} onHide={() => setShowConfirm(false)} />}
        <Spin spinning={loadingDataSme || loadingDataStore || loadingDataConfig}>
            {
                (!!dataConfig?.vrConfigCommission?.success && dataConfig?.vrConfigCommission?.settings?.length == 0) ?
                    <Card>
                        <Flex align='center' justify='center' vertical gap={8}>
                            <Text>
                                Tài khoản UpS {currentSME?.email} chưa được cấu hình Commmission. Hãy cấu hình ngay
                            </Text>
                            <Button
                                type="primary"
                                className="btn-base btn primary"
                                onClick={() => {
                                    navigate('/settings/cms-create', { state: { sme_id: state?.sme_id, init: dataConfig?.vrConfigCommission?.init } })
                                }}
                            >
                                Cấu hình CMS
                            </Button>
                        </Flex>
                    </Card>
                    : <>
                        <Card style={{ marginBottom: 20 }}>
                            <Row justify="end" align="middle" gutter={10}>
                                <Col span={4}>
                                    <Text strong>UpS ID: {state?.sme_id}</Text>
                                </Col>
                                <Col span={20}>
                                    <Flex justify="end" align="center" gap={10}>
                                        <Tooltip placement="bottom" title={`Tài khoản được chỉnh sửa cấu hình commission gần nhất lúc ${dataConfig?.vrConfigCommission?.last_updated_at ? dayjs(dataConfig?.vrConfigCommission?.last_updated_at).format('DD/MM/YYYY HH:mm') : '--'}`}>
                                            <Text><HistoryOutlined style={{ fontSize: 20, color: '#ff5629' }} /></Text>
                                        </Tooltip>
                                        <Button
                                            type="primary"
                                            className="btn-base btn primary"
                                            onClick={() => {
                                                navigate('/settings/cms-create', { state: { sme_id: state?.sme_id, configs: configs } })
                                            }}
                                        >Chỉnh sửa</Button>
                                        <Button
                                            className="btn-base btn primary color-base"
                                            onClick={() => {
                                                setShowConfirm(true)
                                            }}
                                            color="#ff5629"
                                        >Chạy lại tính toán CMS</Button>
                                        <Tooltip placement="bottom" title={` Tài khoản vừa chạy lại báo cáo  lúc ${dataConfig?.vrConfigCommission?.last_executed_at ? dayjs(dataConfig?.vrConfigCommission?.last_executed_at * 1000).format('DD/MM/YYYY HH:mm') : '--'}`}>
                                            <Text><FieldTimeOutlined style={{ fontSize: 20, color: '#ff5629' }} /></Text>
                                        </Tooltip>
                                    </Flex>
                                </Col>
                            </Row>
                        </Card>
                        {
                            !!configs?.length && configs.map((config, index) => {
                                return <Card style={{ marginBottom: 20 }}>
                                    <CardCommission dataStore={dataStore} config={config} configs={configs} setConfigs={setConfigs} index={index} />
                                </Card>
                            })
                        }
                    </>
            }
        </Spin>
    </UserSettingWrapper >
};

export default CommissionConfig;
